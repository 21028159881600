var Placeholders = {};

import Generator from 'Placeholders.mod/Generator';
Placeholders.Generator = Generator;
export {Generator};

import Widget from 'Placeholders.mod/Widget';
Placeholders.Widget = Widget;
export {Widget};


export default Placeholders;
