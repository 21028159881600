import baseConfig from './base.js';

const config = {
	// encode the minimum set of characters (&,<,>,") for easier post-processing
	entity_encoding: 'raw',
	// content editable div will act as root (instead of wrapping everything in p tags)
	forced_root_block: false,
	// no placeholder resizing
	object_resizing: false,
	plugins: 'common placeholders contentcleaner placeholderattribute validate',
	selector: '#subject',
	// no styling allowed
	toolbar: false,
	hidden_input: false,
	// aside from plain-text, only placeholder images are valid
	valid_elements: 'img[!data-is-placeholder|class<placeholder|src|data*]',
	validate_maxlength: 250,
	validate_required: true,
	setup: (editor) => {
		editor.on('keyDown', function (event) {
			if (event.keyCode === 13) {
				// no enter
				event.preventDefault();
			}
		});
	}
};

export default Object.assign({}, baseConfig, config);
