import { getExtFromFileName, humanFileSize } from 'BambooHR.util';
import {
	updateFileUploadActions,
	centerFileUploadActions,
	uploadModalContent,
} from 'FileManager.mod';

/**
 * TemporaryFiles defines the addFiles[] array
 * It has two contexts:
 *     1) Edit Template: The array of new files to attach to a template
 *     2) Send Email:  The array of all files to send (Both the template files and newly attached files)
 */
let temporaryFiles = [];

/**
 * Update template files
 *
 * @param {object} files
 */
export function updateTemplateFiles(files) {
	clearTemporaryFiles();
	$('.js-attached-files').empty().append(getFilesHtml(files, 'template'));
	updateUploadStatusText();
}

/**
 * Re-render the temporary files that are pending transfer to company files
 */
function updateNewFiles() {
	const $filesContainer = $('.js-attached-files');
	$filesContainer.find('.js-file').not('[data-template-file]').remove();
	$filesContainer.append(getFilesHtml(temporaryFiles, 'new'));
	$('.dot-ellipsis').dotdotdot({ wrap: 'letter' });
	updateUploadStatusText();
}

/**
 * Get the files HTML
 *
 * @param {object} files
 *
 * @returns {string}
 */
function getFilesHtml(files, context) {
	let fileList = '';
	files.forEach(function(file) {
		let fileId = file.id || '';
		let fileValue = file.value || '';
		let template = '';

		if (context == 'template') {
			template = `data-template-file="true"`;
		}

		fileList +=
			`<div class="FileCard js-file" data-id="${ BambooHR.Utils.sanitizeHtml(fileId) }" data-name="${ BambooHR.Utils.sanitizeHtml(file.name) }" data-type="${ BambooHR.Utils.sanitizeHtml(file.type) }" data-size="${ BambooHR.Utils.sanitizeHtml(file.size) }" data-value="${ BambooHR.Utils.sanitizeHtml(fileValue) }" ${ BambooHR.Utils.sanitizeHtml(template) }>
			<span class="FileCard__detail">
				<span class="FileCard__title dot-ellipsis" title="${ BambooHR.Utils.sanitizeHtml(file.name) }">${ BambooHR.Utils.sanitizeHtml(file.name) }</span>
			</span>
			<span class="FileCard__info">
				<span class="FileCard__icon">
					<ba-file-icon name="${ file.name }"></ba-file=icon>
				</span>
				${ getExtFromFileName(file.name).toUpperCase() } (${ humanFileSize(file.size) })
			</span>
			<span class="FileCard__close js-remove-file"></span>
			<input type="hidden" name="addFiles[]" value="${ BambooHR.Utils.sanitizeHtml(fileValue) }" />
		</div>`;
	});
	return fileList;
}

/**
 * Clean up file uploader events
 */
export function cleanupModal() {
	$(document).off('click', '.js-add-files-btn');
	$(document).off('click', '.js-attached-files .js-remove-file');
}

/**
 * Open the temporary file uploader modal and set meta-data on successful upload
 */
function openModal(stacked) {
	const fabricModalSelector = '.js-emailFileUploadContext';
	const primaryAction = () => {
		const localStacked = stacked;
		$(fabricModalSelector).find(':input[name^=attachId], ba-select[name^=attachId]').each(function(index, input) {
			temporaryFiles.push({
				name: input.getAttribute('data-name'),
				type: input.getAttribute('data-type'),
				value: input.getAttribute('value'),
				size: input.getAttribute('data-size'),
				image: input.getAttribute('data-image')
			});
		});
		updateNewFiles();
		if (localStacked) {
			window.BambooHR.Modal.setState({ sheetProps: { isOpen: false } }, true);
		} else {
			window.BambooHR.Modal.setState({ isOpen: false });
		}
	};
	const options = {
		content: (
			<div className="js-emailFileUploadContext">
				{ uploadModalContent }
			</div>
		),
		onClose: () => {
			if (stacked) {
				window.BambooHR.Modal.setState({ sheetProps: { isOpen: false } }, true);
			} else {
				window.BambooHR.Modal.setState({ isOpen: false });
			}
		},
		onOpen: () => {
			$('.FileUploadActions').addClass('centerContent');
			let context = $('.multiUpload');
			let options = {
				linkHTML: `<span class="attach-link">
							<button class="fab-Button fab-Button--secondary fab-Button--noSpace js-chooseFile" type="button">` + $.__('Choose File(s)') + ` </button>
							<button class="files-add-files fab-TextButton fab-link" type="button" style="display: none;"> + ` + $.__('Add a file') + ` </button>
						</span>`,
				success: function (data, elm) {
					$('.noFile', context).hide();
					let $form = $('.attach-wrapper', context).closest('form');
					$form.append(`<input
						type="hidden"
						name="attachId[]"
						data-name="${ data.name }"
						data-type="${ data.type }"
						data-size="${ data.size }"
						data-image="${ data.image }"
						value="${ data.fid }"
					/>`);
					updateModalInputs(true, primaryAction, stacked);
				},
				removeFile: function(elm) {
					let fid = elm.attr('id').replace('file-', '');
					if (elm.hasClass('uploadDone')) {
						$(`input[name^=attachId][value="${ fid }"]`).remove();
					}
					if (updateFileUploadActions(context)) {
						updateModalInputs(false, null, stacked);
					}
					return true;
				},
				onRemove: function() {
					if (updateFileUploadActions(context)) {
						updateModalInputs(false, null, stacked);
					}
				},
				createFileWrapper: function(name, progress) {
					$('.noFile', context).hide();
					if ($('.files > *', context).length === 0) {
						$('.attach-link button, .uploadDesc', context).toggle();
						centerFileUploadActions(context, false);
					}
					return $.fileUploader.createFileWrapper(name, progress);
				},
				setDragOverValues: function(wrapper) {
					wrapper.addClass('dragOver').find('.upload-msg').text($.__('Upload Files'));
				},
				setDragOffValues: function() {
					$('.dragOver').find('.upload-msg').text($.__('Drop Files Here to Upload'));
					$('.dragOver').removeClass('dragOver');
				},
				uploaderId: 'multiUpload'
			};
			$('.attach-wrapper', context).closest(fabricModalSelector).fileUploader(options);
			$('.uploadDesc').show();
			$(fabricModalSelector).find(':input[name^=attachId], ba-select[name^=attachId]').remove();
		},
		primaryAction: null,
		primaryActionText: $.__('Attach'),
		isOpen: true,
		title: $.__('Upload Files')
	};

	if (stacked) {
		window.BambooHR.Modal.setState({ sheetProps: options }, true);
	} else {
		window.BambooHR.Modal.setState(options);
	}
}

function updateModalInputs (enable, primaryAction, stacked) {
	if (stacked) {
		if (enable) {
			window.BambooHR.Modal.setState({ isOpen: true, sheetProps: { primaryAction: primaryAction } }, true);
		} else {
			window.BambooHR.Modal.setState({ isOpen: true, sheetProps: { primaryAction: null } }, true);
		}
	} else if (enable) {
		window.BambooHR.Modal.setState({ primaryAction: primaryAction }, true);
	} else {
		window.BambooHR.Modal.setState({ primaryAction: null }, true);
	}
}

/**
* Get the total size of all files attached
*/
function getSize() {
	let $files = $('.js-attached-files').find('.js-file');

	let size = 0;
	for (let i = 0; i < $files.length; i++) {
		size += Number($files[i].dataset.size);
	}

	return size;
}

/**
* Update the status text based on size of files
* TODO: This logic should probably live in the file-card module
*/
function updateUploadStatusText() {
	const $fileStatus = $('.js-new-files-status');
	let size = getSize();
	let text = $.__('Total attachment size right now is %s. For emails, it\'s best to keep it under 10MB.', `<strong>${ BambooHR.Utils.humanFileSize(size) }</strong>`);

	if (size > 10433333) {
		$fileStatus.html(text);
	} else {
		$fileStatus.empty();
	}
}

export function addListeners() {
	$(document).on('click', '.js-add-files-btn', function() {
		openModal();
		return false;
	});

	$(document).on('click', '.js-attached-files .js-remove-file', function(e) {
		const $filesContainer = $('.js-attached-files');

		let $file = $(this).closest('.js-file');
		if ($file.data('template-file') !== undefined) {
			// This file is associated with this template, add it to the deleteFiles[] list
			$filesContainer.append('<input type="hidden" name="deleteFiles[]" value="' + $file.data('id') + '" />');
		}

		let fileValue = $file.data('value');
		if (typeof fileValue != 'undefined') {
			// Remove it from the temporaryFiles array
			temporaryFiles = temporaryFiles.filter(function(file) {
				return file.value !== fileValue;
			});
		}

		$file.remove();

		updateUploadStatusText();
	});
}

/**
 * Empty the temporary files array
 * @return {void}
 */
export function clearTemporaryFiles() {
	temporaryFiles.length = 0;
}
