import { consume } from '../../store/context';
import { Select } from '@fabric/select';
import { ifFeature } from '@bamboohr/utils/lib/feature';

export function TimesheetDropdown(props) {
	const {
		employeeTimesheets,
		focusedTimesheet: { id: focusedTimesheetId },
		latestTimesheetId,
		latestPreviousTimesheetId,
	} = props;

	const timesheetList = employeeTimesheets.map((ts) => {
		let text;
		const intTimesheetId = Number(ts.id);

		if (latestTimesheetId === intTimesheetId) {
			text = $.__('This Pay Period');
		} else if (latestPreviousTimesheetId === intTimesheetId) {
			text = $.__('Previous Pay Period');
		} else {
			text = `${ moment(ts.start).format() } – ${ moment(ts.end).format() }`;
		}

		return {
			text,
			value: intTimesheetId,
		};
	});

	function handleJadeSelectChange(param) {
		window.location = makeUrl(latestTimesheetId, param);
	}

	return (
		<div className="TimesheetHeader__dropdown">
			{/* @startCleanup encore */}
			{ifFeature('encore', null, <div className="TimesheetHeader__dropdownLabel">{ $.__('Show') }</div>)}
			{/* @endCleanup encore */}
			<div className="TimesheetHeader__period">
				<Select
					variant={ifFeature('encore', 'single')}
					isClearable={ false }
					items={ timesheetList }
					onChange={ handleJadeSelectChange }
					selectedValues={ [focusedTimesheetId] }
					showSearch="never"
					size="small"
					width="7"
				/>
			</div>
		</div>
	);
}

export default consume([
	'employeeTimesheets',
	'focusedTimesheet',
	'latestTimesheetId',
	'latestPreviousTimesheetId',
], TimesheetDropdown);

export function makeUrl(latestTimesheetId, param) {
	const timesheetIdParam = latestTimesheetId === param[0] ? '' : `&et_id=${ param[0] }`;
	return `/employees/timesheet/?id=${ window.Employee.id }${ timesheetIdParam }`;
}
