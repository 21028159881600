// Utils
import { throttle } from 'lodash';
import { ifFeature } from '@bamboohr/utils/lib/feature';

let scrollHandler;
let sideColumn;
let sideColumnScroll;

let isSticky = false;

/**
 * Initialize the sticky sidebar
 * @return {void}
 */
export function initializeStickySidebar(container, containerScroll) {
	// Remove any existing listeners
	destroyStickySidebar();

	sideColumn = document.querySelector(container);
	sideColumnScroll = document.querySelector(containerScroll);
	scrollHandler = throttle(sidebarStickOnScroll, 10);

	if (sideColumn && sideColumnScroll) {
		window.addEventListener('scroll', scrollHandler);
	}
}

/**
 * Remove all sticky sidebar listeners
 * @return {void}
 */
function destroyStickySidebar() {
	window.removeEventListener('scroll', scrollHandler, false);
}

/**
 * Make the sidebar sticky & update scrolling
 * @return {void}
 */
function sidebarStickOnScroll() {
	const curScroll = sideColumnScroll.scrollTop;
	const NavOffest = ifFeature('encore', 111, 80);
	if (sideColumn.getBoundingClientRect().top < NavOffest) {
		sideColumn.style.height = `${sideColumn.offsetHeight}px`;
		sideColumnScroll.classList.add('fixed');
		sideColumnScroll.scrollTop = curScroll;
		isSticky = true;
	} else {
		sideColumn.style.height = null;
		sideColumnScroll.classList.remove('fixed');
		isSticky = false;
	}
}
