/**
 * Create a selected and unselected image for each placeholder (in the users
 * language)
 *
 * We use images so TinyMCE will treat each placeholder as a separate Node
 */
import { getFabricBrandColor, Mediator } from 'BambooHR.util';
import { ifFeature } from '@bamboohr/utils/lib/feature';
import fabEncoreColors from '@bamboohr/fabric/dist/definitions/json/encore-colors.json';
import fabJadeColors from '@bamboohr/fabric/dist/definitions/json/colors.json';

const fabFont = ifFeature('encore', 'Inter, sans-serif', 'lato');
const fabColors = ifFeature('encore', fabEncoreColors?.colors, fabJadeColors?.colors);

const placeholders = {};

/**
 * image details
 */
const originalTextHeight = ifFeature('encore', 14, 11);
const originalLetterSpacing = ifFeature('encore', '0.5px', '0px');
const originalHorizontalPadding = ifFeature('encore', 4, 4);
const originalRectHeight = ifFeature('encore', 22, 18);
const originalRectBorder = 0;
const originalRectRadius = ifFeature('encore', 4, 2);
const textYOffset = ifFeature('encore', 2, 1);

let textHeight;
let horizontalPadding;
let rectHeight;
let rectBorder;
let rectRadius;
const error = fabColors.error || '#bd5800';
const white = fabColors.white || '#fff';

/**
 * scale image detail values
 *
 * @param int scale
 */
function scaleValues(scale) {
	textHeight = originalTextHeight * scale;
	horizontalPadding = originalHorizontalPadding * scale;
	rectHeight = originalRectHeight * scale;
	rectBorder = originalRectBorder * scale;
	rectRadius = originalRectRadius * scale;
}

/**
 * draw a rect with rounded corners
 *
 * @param context ctx
 * @param int x x-origin
 * @param int y y-origin
 * @param int w width
 * @param int h height
 * @param int r radius (~border-radius)
 *
 * @return context with rounded rectangle
 */
function roundRect(ctx, x, y, w, h, r) {
	if (w < 2 * r) {
		r = w / 2;
	}
	if (h < 2 * r) {
		r = h / 2;
	}
	ctx.beginPath();
	ctx.moveTo(x + r, y);
	ctx.arcTo(x + w, y, x + w, y + h, r);
	ctx.arcTo(x + w, y + h, x, y + h, r);
	ctx.arcTo(x, y + h, x, y, r);
	ctx.arcTo(x, y, x + w, y, r);
	ctx.closePath();
	return ctx;
}

/**
 * trim canvas to needed dimensions for placeholder
 *
 * @param canvas canvas with text for sizing determination
 *
 * @return canvas
 */
function getTrimmedCanvas(canvas) {
	const ctx = canvas.getContext('2d');

	let w = canvas.width;
	let h = canvas.height;
	const pix = { x: [], y: [] };
	const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
	let x;
	let y;
	let index;

	for (y = 0; y < h; y++) {
		for (x = 0; x < w; x++) {
			index = (y * w + x) * 4;
			if (imageData.data[index + 3] > 0) {
				pix.x.push(x);
				pix.y.push(y);
			}
		}
	}

	pix.x.sort(function (a, b) {
		return a - b;
	});
	pix.y.sort(function (a, b) {
		return a - b;
	});

	const n = pix.x.length - 1;

	w = pix.x[n] - pix.x[0];
	h = pix.y[n] - pix.y[0];

	const freshCanvas = document.createElement('canvas');
	freshCanvas.width = w + (horizontalPadding + rectBorder) * 2;
	freshCanvas.height = rectHeight + rectBorder * 2;
	return freshCanvas;
}

/**
 * draw styled text on canvas
 *
 * @param canvas
 * @param string text to draw
 * @param string color
 * @param int x-origin
 *
 * @return canvas
 */
function drawText(canvas, text, color, x) {
	if (typeof x === 'undefined') {
		x = horizontalPadding + rectBorder;
	}
	const ctx = canvas.getContext('2d');
	ctx.font = `${textHeight}px ${fabFont}`;
	ctx.fillStyle = color || fabColors.gray9;
	ctx.letterSpacing = originalLetterSpacing;
	ctx.fillText(text, x, textHeight + textYOffset);
	return canvas;
}

/**
 * create base canvas to use for placeholder painting
 *
 * @param string name placeholder text
 *
 * @return canvas
 */
function getSizedCanvas(name) {
	const canvas = document.createElement('canvas');
	canvas.width = 1000;
	canvas.height = rectHeight + rectBorder * 2;
	return getTrimmedCanvas(drawText(canvas, name, '#000', 0));
}

/**
 * draw background rectangle for placeholder
 *
 * @param canvas
 * @param string fill color
 * @param string stroke color
 *
 * @return canvas
 */
function drawRectangle(canvas, fill, stroke) {
	let ctx = canvas.getContext('2d');
	if (rectBorder > 0) {
		ctx = roundRect(ctx, rectBorder / 2, rectBorder / 2, canvas.width - rectBorder, canvas.height - rectBorder, rectRadius);
		let strokeColor = stroke || fabColors.gray4;
		strokeColor = `#${(parseInt(strokeColor.substring(1), 16) - parseInt(303030, 16)).toString(16)}`; // this stroke adds ~#303030 to the given color; compensate.
		ctx.strokeStyle = strokeColor;
		ctx.lineWidth = rectBorder;
		ctx.stroke();
	} else if (rectRadius > 0) {
		ctx = roundRect(ctx, 0, 0, canvas.width, canvas.height, rectRadius);
	} else {
		ctx.rect(0, 0, canvas.width, canvas.height);
	}
	ctx.fillStyle = fill || ifFeature('encore', fabColors.gray1, fabColors.gray3);
	ctx.fill();
	return canvas;
}

/**
 * is the window being rendered on a high-density display?
 *
 * @return bool
 */
function isRetinaDisplay() {
	if (window.matchMedia) {
		const mq = window.matchMedia(
			'only screen and (min--moz-device-pixel-ratio: 1.3), only screen and (-o-min-device-pixel-ratio: 2.6/2), only screen and (-webkit-min-device-pixel-ratio: 1.3), only screen  and (min-device-pixel-ratio: 1.3), only screen and (min-resolution: 1.3dppx)'
		);
		return (mq && mq.matches) || window.devicePixelRatio > 1;
	}
	return false;
}

/**
 * build a single placeholder (both selected and unselected states)
 *
 * @param string name placeholder text
 *
 * @return object placeholder
 */
export function buildPlaceholder(name) {
	name = name.replace('&amp;', '&');
	ifFeature('encore', scaleValues(1), scaleValues(isRetinaDisplay() ? 2 : 1));
	let canvas = getSizedCanvas(name);
	const unselected = drawPlaceholderImage(canvas, name, '', '', '');
	const selected = drawPlaceholderImage(canvas, name, fabColors.gray7, fabColors.gray8, fabColors.white);
	const highlighted = drawPlaceholderImage(canvas, name, getFabricBrandColor(), '', fabColors.white);
	ifFeature('encore', scaleValues(1), scaleValues(isRetinaDisplay() ? 2 : 1));
	canvas = getSizedCanvas(name);
	const drag = drawPlaceholderImage(canvas, name, fabColors.gray7, fabColors.gray8, fabColors.white);
	return {
		drag,
		selected,
		unselected,
		highlighted,
		name,
	};
}

/**
 * build all placeholders
 *
 * @param object data Placeholder.Data
 */
function buildPlaceholders(data) {
	for (const placeholder in data) {
		placeholders[placeholder] = buildPlaceholder(data[placeholder].name.replace('&amp;', '&'));
	}
	Mediator.publish('placeholders:generator:post-build', placeholders);
}

/**
 * @param canvas
 * @param string name
 * @param string fill
 * @param string stroke
 * @param string textColor
 * @returns {string|String}
 */
function drawPlaceholderImage(canvas, name, fill, stroke, textColor) {
	canvas = drawRectangle(canvas, fill, stroke);
	canvas = drawText(canvas, name, textColor);
	return canvas.toDataURL();
}

/**
 * @param string name example sender__first_name
 * @returns {{drag, selected, unselected}|*}
 */
function buildInvalidPlaceholders(name) {
	name = tokenToUserFriendlyName(name);
	ifFeature('encore', scaleValues(1), scaleValues(isRetinaDisplay() ? 2 : 1));
	const canvas = getSizedCanvas(name);
	const selected = drawPlaceholderImage(canvas, name, error, error, white);
	return {
		drag: selected,
		selected,
		unselected: selected,
	};
}

/**
 * @param string name example sender__first_name
 * @returns string Sender First Name
 */
function tokenToUserFriendlyName(name) {
	name = name.replace('&amp;', '&');
	name = name.replace('__', ' ');
	name = name.replace('_', ' ');
	name = name.replace(/\w\S*/g, function (txt) {
		return txt.charAt(0).toUpperCase() + txt.substr(1);
	});
	return name;
}

const Generator = {
	get() {
		return placeholders;
	},
	generate(data) {
		buildPlaceholders(data);
	},
	generateInvalidPlaceholder(name) {
		return buildInvalidPlaceholders(name);
	},
};

export default Generator;
