import { Icon, IconButton, Tooltip, Headline, IconV2, LayoutBox, Button, BodyText } from '@bamboohr/fabric';
import { Download13x16 } from '@bamboohr/grim';
import { Message } from '@bamboohr/utils/lib/message';
import { TextButton } from '@fabric/button';
import { classNameFromObject } from '@utils/dom';
import { Fragment } from 'react';
import openChangeHistoryModal from 'time-tracking/modals/change-history';
import { getFocusedSheetDateSpanText } from '../../../../utils';
import { consume } from '../../store/context';
import TimesheetDropdown from './timesheet-list-dropdown.react';
import OptionDropdown from './timesheet-option-dropdown.react';
import { ifFeature } from '@bamboohr/utils/lib/feature';
import './styles.styl';

export function Header(props) {
	const {
		focusedTimesheet,
		showHeader,
		isOwnTimesheet,
		isApprovalShowing,
		isLatestTimesheet,
		timesheetOptions,
		changeViewOption,
		employeeTimesheets,
		getFocusedDays,
		getToday,
		isViewOnly,
	} = props;
	const showOptions = isOwnTimesheet && !isApprovalShowing;
	const dropdownProps = {
		timesheetOptions,
		changeViewOption,
	};
	const initialDate = isLatestTimesheet ? getToday().date : undefined;

	return (
		<Fragment>
			{showHeader && (
				<div className="TimesheetHeader">
					<div className="TimesheetHeader__content">
						{ifFeature(
							'encore',
							<IconV2
								color="primary-strong"
								name="stopwatch-regular"
								size={24}
							/>,
							<div className="TimesheetHeader__icon">
								<Icon brand="true" name="fab-timesheet-29x22" />
							</div>,
						)}
						{ifFeature(
							'encore',
							<LayoutBox marginLeft={2}>
								<Headline size="medium">{$.__('Timesheet')}</Headline>
							</LayoutBox>,
							<div className="TimesheetHeader__header">
								<h3>{$.__('Timesheet')}</h3>
							</div>,
						)}
						<div className="TimesheetHeader__controls">
							{employeeTimesheets.length > 1 && <TimesheetDropdown />}
							{showOptions && <OptionDropdown {...dropdownProps} />}
							{ifFeature(
								'encore',
								<Button
									color="secondary"
									onClick={() => openChangeHistoryModal(getFocusedDays(), initialDate)
									}
									size="small"
									startIcon="clock-rotate-left-regular"
									type="button"
									variant="outlined"
								>
									{$.__('History')}
								</Button>,
							)}
							<Tooltip content={$.__('Download PDF')}>
								<IconButton
									color="secondary"
									data-bi-id="time-tracking-print-timesheet-button"
									icon={ifFeature(
										'encore',
										'down-to-line-regular',
										<Download13x16 />,
									)}
									onClick={() => window.open(
											`/timesheet/${focusedTimesheet.id}/print`,
											'_blank',
										)
									}
									size={ifFeature('encore', 'small')}
									target="_blank"
									type="button"
									variant="outlined"
								/>
							</Tooltip>
						</div>
					</div>
				</div>
			)}
			{/* @startCleanup encore */}
			{!isViewOnly &&
				ifFeature(
					'encore',
					null,
					<div className="TimesheetSecondHeader">
						<div className="TimesheetSecondHeader__dates">
							<span className="TimesheetSecondHeader__datesIcon">
								<Icon brand="true" name="fab-stopwatch-20x24" />
							</span>
							<h4>
								{getFocusedSheetDateSpanText(
									focusedTimesheet.start,
									focusedTimesheet.end,
								)}
							</h4>
						</div>
						<div className="TimesheetSecondHeader__controls">
							<TextButton
								clickAction={() => openChangeHistoryModal(getFocusedDays(), initialDate)
								}
								iconBefore="fab-clock-reverse-16x15"
								muted={true}
								size="small"
								text={$.__('Change History')}
								type="button"
							/>
						</div>
					</div>,
				)}
			{/* @endCleanup encore */}
			{focusedTimesheet.workWeekStartsOn?.changed &&
				ifFeature(
					'encore',
					<LayoutBox marginBottom={2}>
						<BodyText
							color="warning-strong"
							icon="triangle-exclamation-regular"
						>
							{$.__n(
								'The work week start day has changed. There is %1$s day where overtime cannot be calculated.',
								'The work week start day has changed. There are %1$s days where overtime cannot be calculated.',
								focusedTimesheet.workWeekStartsOn.count,
							)}
						</BodyText>
					</LayoutBox>,
					<div
						className={classNameFromObject({
							TimesheetHeader__workWeekWarning: true,
							'TimesheetHeader__workWeekWarning--hiddenHeader': !showHeader,
						})}
					>
						<span className="TimesheetHeader__workWeekWarning__icon">
							<Icon name="fab-triangle-exclamation-16x16" />
						</span>
						<p className="TimesheetHeader__workWeekWarning__text">
							<Message
								params={[focusedTimesheet.workWeekStartsOn.count]}
								text={$._n(
									'The work week start day has changed. There is {1} day where overtime cannot be calculated.',
									'The work week start day has changed. There are {1} days where overtime cannot be calculated.',
									focusedTimesheet.workWeekStartsOn.count,
								)}
							/>
						</p>
					</div>,
				)}
		</Fragment>
	);
}

export default consume([
	// Properties
	'isOwnTimesheet',
	'isApprovalShowing',
	'isLatestTimesheet',
	'employeeTimesheets',
	'focusedTimesheet',
	'timesheetOptions',
	'changeViewOption',
	'showHeader',
	'isViewOnly',
	// Actions
	// Queries
	'getFocusedDays',
	'getToday',
], Header);
