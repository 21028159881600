import * as Containers from './containers';
import * as Fields from './fields';
import * as Hover from './fields/hover';
import * as Repeat from './repeat';

import {
	Component,
	FunctionComponent,
	ReactElement,
	createElement,
	useEffect,
	useState,
} from 'react';

import AddressValidation from './address-validation/types/address-validation';
import { initializeScrollableTable } from './utils/legacy-table-helpers';
import { overrideFieldData } from './utils/override-fields';
import { setupDynamicFormDebug } from './debug';
import { useForm } from '@bamboohr/fabric';
import { useIsAppInstalled } from 'use-is-app-installed';

type FormOptions = {
	employeeId?: number | string;
	showPayrollFederal?: boolean;
	showPayrollState?: boolean;
	isNHPPacket?: boolean;
};

type Props = {
	data: DynamicForm.FormData;
	controls?:
		| Partial<typeof controls>
		| { [key: string]: ReactElement | FunctionComponent | Component };
	isAddEmployeeForm?: boolean;
	additionalOptions?: FormOptions;
	customOnSubmit?: ({
		context,
		submitForm,
		onCancel,
	}: AddressValidation.ValidateAddressMethodProps) => void;
};

export const controls = {
	...Fields,
	...Containers,
	...Repeat,
	...Hover,
};

const DynamicForm: FunctionComponent<Props> = ({
	data,
	controls: customControls = {},
	isAddEmployeeForm = false,
	additionalOptions = {},
	customOnSubmit,
}) => {
	// TODO: May be worth converting to useReducer instead of useState since QF->React state has gotten increasingly complicated
	const [formData, setFormData] = useState(data);
	// we cannot focus the error because fabric components validate on native, but also hide it so it cannot be scrolled to
	const validation = useForm([], {
		mode: 'onBlur',
		shouldFocusError: false,
		shouldUnregister: false,
	});
	const { root } = formData;
	const rootData = formData[root];
	let isInitiallyEditable = true;

	const isQuickBooksOnlinePayrollEnabled = useIsAppInstalled(
		'quickbooksOnlinePayroll',
	);

	useEffect(() => {
		initializeScrollableTable();
	}, []);

	if (
		rootData &&
		rootData.settings &&
		Object.prototype.hasOwnProperty.call(rootData.settings, 'isClickToEdit')
	) {
		isInitiallyEditable = false;
	}
	const [isEditable, setIsEditable] = useState(isInitiallyEditable);

	const internalControls = {
		...controls,
		...customControls,
	};

	const {
		employeeId,
		showPayrollFederal = false,
		showPayrollState = false,
		isNHPPacket = false,
	} = additionalOptions;

	// Since we are rendering components directly we are creating our own context object
	// React suggests using props passing unless you are deeply nested, but the `jsonToElementArray` function handles that
	const context: DynamicForm.Context<typeof internalControls> = {
		controls: internalControls,
		formData,
		setFormData,
		formOptions: {
			employeeId,
			isNHPPacket,
			showPayrollFederal,
			showPayrollState,
		},
		form: {
			isAddEmployeeForm,
			isEditable,
			setIsEditable,
			customOnSubmit,
		},
		validation,
	};

	// will allow debug and expose extra helpers
	setupDynamicFormDebug(context);

	const jsonToElementArray = (id: string): React.ReactElement => {
		const json = formData[id];
		const { props, settings, type, children: jsonChildren } = json;
		let jsonType = type;

		if (typeof json === 'undefined' || typeof props === 'undefined') {
			// warn, but allow the form to continue to be built
			console.error(`Dynamic Form: Unable to find element id: ${id}`);
			return null;
		}

		if (jsonType.includes('upload_covid_documents')) {
			jsonType = 'Documents';
		}

		let children = [];
		if (Array.isArray(jsonChildren) && jsonChildren.length > 0) {
			children = jsonChildren.map((el) => {
				return el === null || el === undefined ? null : jsonToElementArray(el);
			});
		}
		const DynamicComponent =
			internalControls[jsonType] || internalControls.FallbackDiv;

		// Eventually, when the BE api can be simplified spread props, settings and move context to a provider so the children don't get rendered every time!
		return createElement(
			DynamicComponent,
			{
				key: props.id,
				context,
				props,
				settings,
				type: jsonType,
				isQuickBooksOnlinePayrollEnabled,
			},
			children,
		);
	};

	return root ? jsonToElementArray(root) : null;
};

/**
 * Wraps the dynamic form so customizations can happen to the data once before render an not re-applied with every state update
 *
 * @param props
 * @returns ReactElement
 */
const DynamicFormCustomizationWrapper: FunctionComponent<Props> = (props) => {
	const data = overrideFieldData(props.data);
	return <DynamicForm {...props} data={data} />;
};

export default DynamicFormCustomizationWrapper;
