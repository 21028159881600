/*global tinyMCE*/
import { ifFeature } from '@utils/feature';

let $widgetList;
let $widgetListEmpty;
let $widgetFilter;
let $widgetFilterClear;
let $widgetListItems;

/**
 * build html for placeholder widget
 *
 * @param object data Placeholder.Data
 * @param object images placeholder images
 */
function buildHtml(data, images) {
	let html = '';
	Object.keys(data).forEach(function (placeholder) {
		html += `
			<li class="PlaceholderWidget__listItem js-placeholder-widgetListItem" data-normalized-name="${data[placeholder].name.toLowerCase()}">
				<div class="PlaceholderWidget__listItem-wrap">
					<div class="PlaceholderWidget__listItem-name">${data[placeholder].name}</div>
					<div class="PlaceholderWidget__listItem-example">${ifFeature('encore', 'e.g.', 'Ex:')} ${data[placeholder].example}</div>
				</div>
				<img
					data-is-placeholder="true"
					id="${placeholder}"
					class="js-placeholder-img"
					draggable="true"
					src="${images[placeholder].drag}"
					data-placeholder-name="${placeholder}"
					aria-label="${data[placeholder].name}"
  					aria-description="Placeholder for ${data[placeholder].name}"
					alt="${data[placeholder].name}"
				/>
			</li>
		`;
	});

	$(html).insertAfter($widgetList.find('.js-placeholder-widgetFilterWrap'));
}

/**
 * create widget and events
 *
 * @param object data Placeholder.Data
 * @param object images placeholder images
 */
function create(data, images) {
	$widgetList = $('.js-placeholder-widgetList');
	buildHtml(data, images);
	$widgetListItems = $('.js-placeholder-widgetListItem');

	$widgetListEmpty = $('.js-placeholder-widgetListEmpty');
	$widgetFilter = $('.js-placeholder-widgetFilter');
	$widgetFilter.on('input', handleFilter);
	//remove email modal specific attributes.
	$widgetFilter.removeClass('PlaceholderWidget__listFilter--muteFocusOnLoad');
	$widgetFilter.attr('readonly', false);

	$widgetFilterClear = $('.js-placeholder-widgetFilterClear');
	$widgetFilterClear.on('click', handleClearFilter);

	$('.js-placeholder-img')
		.on('dragstart mouseup', function () {
			let that = this;
			this.style.opacity = 1;
			setTimeout(function () {
				that.removeAttribute('style');
			});
		})
		.on('mousedown', function (e) {
			this.style.opacity = 0;
			this.style.width = this.naturalWidth + 'px';
			this.style.height = this.naturalHeight + 'px';
			this.style.top = e.offsetY - this.naturalHeight / 2 + 'px';
			this.style.left = e.offsetX - 1 + 'px';
		})

		// dragEnded is to prevent the hover state from getting stuck
		.on('mouseover', function () {
			$(this).parent().removeClass('dragEnded');
		})
		.on('dragend', function () {
			$(this).parent().addClass('dragEnded');
		})

		.on('click', function (e) {
			tinyMCE.execCommand('insert', $(this).attr('id'));
		});
}

/**
 * Destroy widget (really remove all listeners)
 */
function destroy() {
	$('.js-placeholder-widgetList').find('.PlaceholderWidget__listItem').remove();
	$('.js-placeholder-img').off('dragstart mouseup').off('mousedown').off('dragend').off('click');
}

function filter(normalizedValue) {
	$widgetFilterClear.toggle(!!normalizedValue);
	$widgetListItems.each(function () {
		const $item = $(this);
		$item.toggle($item.data('normalized-name').includes(normalizedValue));
	});
	$widgetListEmpty.toggle(!$widgetListItems.find(':visible').length);
}

function handleClearFilter() {
	$widgetFilter.val('');
	filter('');
	$widgetFilter.blur();
	$widgetFilter.focus();
}

function handleFilter(event) {
	const normalizedValue = event.target.value.toLowerCase();
	filter(normalizedValue);
}

const Widget = {
	create,
	destroy,
};

export default Widget;
